var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-md-flex align-items-center"},[_c('div',{staticClass:"w-100"},[_c('VuePerfectScrollbar',{staticClass:"scrlbar",staticStyle:{"height":"430px"}},[_c('div',[_c('ul',{ref:"chatLog",staticClass:"p-3 chat-list"},_vm._l((_vm.chatConversation),function(chat,index){return _c('li',{key:index,staticClass:"d-flex align-items-start",class:{
                'flex-row-reverse right-msg': chat.fromMe,
                'mt-4 left-msg': index,
              }},[_c('img',{staticClass:"m-0 flex-no-shrink rounded-circle",class:chat.fromMe ? 'ml-3' : 'mr-3',attrs:{"width":"37","src":require(`@/assets/images/users/${chat.conversationImg}`)}}),_c('div',{staticClass:"chat p-3 mb-2",class:{
                  'chat-sent-chat bg-primary text-white': chat.fromMe,
                  'bg-light': !chat.fromMe,
                }},[_c('span',[_vm._v(_vm._s(chat.chat))])])])}),0)])]),_c('div',{staticClass:"d-flex border-top p-3"},[_c('b-form-input',{staticClass:"w-100 border-0",attrs:{"placeholder":"Type Your Message Here","type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addMessage.apply(null, arguments)}},model:{value:(_vm.newMessage),callback:function ($$v) {_vm.newMessage=$$v},expression:"newMessage"}}),_c('b-button',{staticClass:"float-right rounded-circle b-avatar",attrs:{"variant":"primary"},on:{"click":_vm.addMessage}},[_c('feather',{staticClass:"feather-sm",attrs:{"type":"send"}})],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }